import '../css/about-me.css';

function AboutMe() {
	return (
		<a name='about-me'>
			<div class='about-me'>
				<div class='sub-about-me-1'>
					ABOUT ME
				</div>
				<div class='sub-about-me-2'>
					PICTURE
				</div>
			</div>
		</a>
	);
}

export default AboutMe;
