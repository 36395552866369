import '../css/title.css';

function Header() {
	return (
		<div class='title'>
			<div class='sub-title-1'>
				STEPHEN MATHENY
			</div>
			<div class='sub-title-3'>
                SOFTWARE ENGINEER
			</div>
		</div>
	);
}

export default Header;
