import '../css/experience.css';

function Experience() {
	return (
		<a name='experience'>
			<div class='experience'>
				EXPERIENCE/EDUCATION
			</div>
		</a>
	);
}

export default Experience;
