import '../css/contact.css';

function Contact() {
	return (
		<a name='contact'>
			<div class='contact'>
				CONTACT
			</div>
		</a>
	);
}

export default Contact;
