import '../css/skills.css';

function Skills() {
	return (
		<a name='skills'>
			<div class='skills'>
				<div class='sub-skills-1'>
					SKILLS
				</div>
				<div class='sub-skills-2'>
					ANIMATION
				</div>
			</div>
		</a>
	);
}

export default Skills;
