import '../css/footer.css';

function Footer() {
	return (
		<div class='footer'>
            FOOTER
		</div>
	);
}

export default Footer;
